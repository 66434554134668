@font-face {
  font-family: 'ABCDiatype';
  src: url('../fonts/ABCDiatype-Light.otf') format('opentype');
}

body, .admin-container, .admin-title, .orders-list, .order-card, .order-title, .order-detail,
.adminContainer, .adminTitle, .ordersList, .orderCard, .orderTitle, .orderDetail, .filterContainer,
.orderItem, .productName, .smallText, .firebaseLink, .keyMetrics, .metricBox, .primary, .secondary, .tertiary {
  font-family: 'ABCDiatype', sans-serif; /* Standard fallback font */
}

    .admin-container {
    padding: 20px;
    background-color: #f7f6eb;
    min-height: 100vh;
  }
  
  .admin-title {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .orders-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .order-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: transform 0.3s;
  
    &:hover {
      transform: translateY(-10px);
    }
  }
  
  .order-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .order-detail {
    font-size: 16px;
    color: #555;
  }

  .adminContainer {
    padding: 20px;
    background-color: #f7f6eb;
  }
  
  .adminTitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .ordersList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .orderCard {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: calc(30% - 20px);
    
    /* Mediespørring for mobile enheter */
    @media (max-width: 768px) {
      width: calc(40%);
    }
  }
  
  .orderTitle {
    font-size: 18px;
    color: #444;
    margin-bottom: 10px;
  }
  
  .orderDetail {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
  }

  .filterContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 2 kolonner med lik bredde */
    gap: 20px; /* Mellomrom mellom elementene */
    margin-bottom: 20px; /* Ekstra plass under grid */
  
    label, select {
      width: 100%; /* Sørger for at elementene fyller hele cellen */
    }
  
    label {
      margin-bottom: 5px; /* Litt plass under etikettene */
    }
  
    select {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  .orderItem {
    margin-bottom: 10px;
  }
  
  .productName {
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
  }

  .smallText {
    font-size: 0.9em;
  }

  .firebaseLink {
    // Eksempel på styling
    padding: 10px;
    background-color: #007bff;
    color: white;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
  }

  .keyMetrics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .metricBox {
    padding: 15px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    span:first-child {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  
  .primary {
    background-color: #8CD7FF; 
  }
  
  .secondary {
    background-color: #C9A83B; 
  }
  
  .tertiary {
    background-color: #009501; 
  }