.CategoriesContainer {
  display: flex;
  overflow: auto;

  input[type="radio"] {
    border: none;
    position: absolute;
    width: 100%;
    opacity: 0;
    margin: 0;
    max-width: 1320px;
  }

  input[type="radio"] + label {
    content: none;
    background-color: var(--color-brown);
  }

  input[type="radio"]:checked + label {
    content: "";
    background-color: var(--color-white);
  }

  label {
    align-items: flex-start;
    display: flex;
    height: 250px;
    width: 330px;
  }
}
