.NavListContainer {
  padding-left: 0;
  max-width: 1302px;
  width: 100%;
  margin: 0;
}

.SectionBackClick {
  border-top: 1px solid #000000e6;
  border-bottom: 1px solid var(--color-black);
  background-color: rgb(255, 255, 255);
  margin-bottom: 65px;

  button {
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: var(--color-black);
    padding: 0 25px;
  }

  & p {
  }

  & .Icon {
    width: 9px;
    height: 17px;
    margin-right: 1em;
  }
}

.NavListItem {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  min-height: 65px;
}
