.Layout {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  max-width: 1302px;

  & .Overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.LayoutFixed main {
  margin-top: 150px;
}
