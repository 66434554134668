.SectionOrderText {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-black);
  padding: 20px 25px;
  background-color: rgb(255, 255, 255);

  & span {
    & p {
      margin: 0;
    }
  }

  & .OrderCategory {
    display: block;
  }

  & .OrderButtons {
    display: flex;
    align-items: center;

    button {
      padding: 0;
      border: none;
      outline: none;
      font: inherit;
      color: inherit;
      background: none;
      cursor: pointer;
    }

    & .ReduceButton {
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
