.SectionProductText {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
  min-height: 65px;

  &:last-child {
    border-bottom: none;
  }

  & .Number {
    align-items: center;
    border: 1px solid var(--color-black);
    display: flex;
    height: 43px;
    justify-content: center;
    width: 43px;
    border-radius: 50%;
    background-color: var(--color-aqua);
    margin: auto 0;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    padding: 0 25px;
    color: var(--color-black);
    text-align: left;
  }

  @media screen and (min-width: 1302px) {
    button:hover {
      background-color: var(--color-brown);
    }
  }
}
