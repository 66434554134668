.Footer {
  & .ContactInfo {
    text-align: center;

    padding-top: 4rem;
    padding-bottom: 4rem;

    & img {
      max-height: 190px;
    }

    & .Goal {
      margin-left: auto;
      margin-right: auto;
      padding: 0 60px 60px 60px;
    }

    a {
      color: var(--color-black);
    }
  }
}
