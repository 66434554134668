.SectionNavText {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-black);
  background-color: rgb(255, 255, 255);
  min-height: 65px;

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    color: var(--color-black);
    text-align: left;
    padding: 0;
    p {
      padding: 0 25px;
    }
  }

  @media screen and (min-width: 1302px) {
    button:hover {
      background-color: var(--color-brown);
    }
  }
}
