.Home {
  & .Categories {
    border-left: 1px solid black;

    display: flex;
    overflow: auto;
  }

  figure {
    margin: 0;
  }

  .DecorativeImg {
    width: 100%;
    height: 100vw;
    object-fit: cover;
    border-bottom: 1px solid var(--color-black);

    @media screen and (min-width: 1302px) {
      max-height: 1302px;
    }
  }

  .Link {
    height: 65px;
    border-bottom: 1px solid var(--color-black);

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      color: var(--color-black);
      cursor: pointer;
      height: 100%;
      padding: 0 25px;
    }

    @media screen and (min-width: 1302px) {
      a:hover {
        background-color: var(--color-brown);
      }
    }
  }
}
