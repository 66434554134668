.Navbar {
  background-color: var(--color-white);
  max-width: 1302px;
  width: 100%;
  position: relative;

  button {
    background-color: transparent;
    border: none;
  }
  

  & .Navheader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-black);
    z-index: 2;
    height: 85px;
    padding: 0 25px;

    @media screen and (min-width: 1302px) {
      padding-top: 65px;
    }

    & .HamburgerMenu {
      width: 19px;
      height: 35px;
      margin-top: 5px;
    }

    & .ExitCross {
      width: 19px;
      height: 35px;
    }

    & .HamburgerMenu:hover {
      cursor: pointer;
    }

    & .ExitCross:hover {
      cursor: pointer;
    }

    & .Logo:hover {
      cursor: pointer;
    }
  }

  & .SectionTextContainer {
    position: absolute;
    max-width: 1302px;
    width: 100%;
  }
}
