@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ABCDiatype;
  src: url(fonts/ABCDiatype-Light.otf);
}


.abcdiatype-font {
  font-family: 'ABCDiatype', sans-serif; /* Legg til en fallback skrifttype */
}

:root {
  --text-size: 20px;
  --heading-size: 30px;
  --color-brown: #f7f6eb;
  --color-white: #fff;
  --color-black: #000;
  --color-black-opacity: rgba(0, 0, 0, 0.6);
  --color-aqua: #8cd7ff;
  --color-yellow: #d3ff8c;
  --color-warning: #ffa7dd;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1302px;
  width: 100%;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
label {
  cursor: pointer;
}

@media only screen and (min-width: 1302px) {
  body {
    border-left: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
    min-height: 100vh;
  }
}

h1,
h2 {
  font-size: var(--heading-size);
  font-weight: lighter;
}

h1,
h2,
h3 {
  font-family: ABCDiatype;
}

a,
p,
label,
input,
button,
li {
  font-family: ABCDiatype;
  font-size: var(--text-size);
}

textarea {
  font-family: ABCDiatype;
  font-size: 20px;
}

form.submitted input:invalid {
  background-color: var(--color-warning);
}
