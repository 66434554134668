.SectionOrderSummary {
  display: flex;
  border-top: 1px solid black;
  background-color: var(--color-aqua);
  width: 100%;
  max-width: 1302px;
  position: fixed;
  bottom: 0;
  z-index: 2;
  justify-content: center;
  height: 65px;

  @media screen and (min-width: 1302px) {
    top: 0;
    bottom: auto;
    border-bottom: 1px solid var(--color-black);
  }

  & .NumberOfOrders {
    text-align: left;
    flex-grow: 1;
  }

  & .Wrapper {
    cursor: pointer;

    border: 0;
    background: transparent;
    color: var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    height: 65px;
    width: 100%;
  }

  & img {
    margin-left: 15px;
    width: 15px;

    @media screen and (min-width: 1302px) {
      rotate: 180deg;
    }
  }
}
