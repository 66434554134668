.OrderSummary {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255);
  z-index: 3;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-width: 1302px;
  margin: auto;

  @media screen and (min-width: 1302px) {
    border-left: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
  }

  & .TextArea {
    width: 100%;
    background-color: var(--color-brown);
  }

  & .input {
    width: 100%;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: var(--color-brown);
    height: 95px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    margin-bottom: -5px;
    padding: 20px;

    &::placeholder {
      position: absolute;
    }

    &::-webkit-input-placeholder {
      padding-right: 10px;
    }

    @media screen and (min-width: 1302px) {
      padding: 20px;
    }
  }

  & .input:focus {
    width: 100%;
    min-height: 115px;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: var(--color-brown);

    &::placeholder {
      top: 20px;
      transform: translateY(0);
    }
  }

  & .WrapperTitle {
    align-items: center;
    display: flex;
    height: 85px;
    justify-content: space-between;
    padding: 20px 25px;

    h3 {
      margin: 0;
      font-size: 30px;
      font-weight: 200;
    }

    .ExitCross {
      width: 20px;
    }
  }

  & .CloseButton {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  & .WrapperImg {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;

    & .OrderImg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 250px;
    }
  }

  .SummaryContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .ToCheckout {
      height: 65px;
      background-color: var(--color-yellow);
      align-items: flex-start;
      position: relative;

      button {
        align-items: center;
        background-color: transparent;
        border: none;
        color: var(--color-black);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 0 25px;
        width: 100%;
      }
      & p {
        margin: 0;
      }

      & .Icon {
        height: 17px;
        width: 9px;
      }

      img {
        position: absolute;
        top: 20px;
        right: 25px;
        transform: translateY(25%);
      }
    }

    .Orders {
      overflow: auto;
      min-height: 65px;
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    }

    & .TotalCost {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 65px;
      padding: 0 25px;
      background-color: rgb(255, 255, 255);
      border-bottom: 1px solid var(--color-black);
      border-top: 1px solid var(--color-black);
      margin-top: auto;

      & p {
        margin: 0;
      }
    }
  }
}

.ValidationMessage {
  color: #d9534f; // Rødlig farge for advarsel
  background-color: #f2dede; // Lys bakgrunnsfarge
  padding: 10px;
  text-align: center;
  animation: fadein 0.5s; // Enkel fade-in animasjon

  .WarningIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}