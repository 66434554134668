.OptionBox {
  cursor: pointer;
  border-bottom: 1px solid var(--color-black);
  line-height: 1.5;
  background-color: var(--color-white);

  & .Wrapper {
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
      font-weight: 200;
      font-size: 5.5vw;
    }
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0;

    opacity: 0.6;
  }

  input[type="radio"] {
    border: none;
    position: absolute;
    width: 100%;
    opacity: 0;
  }

  input[type="radio"] + label {
    content: none;
  }

  input[type="radio"]:checked + label {
    content: "";
    background-color: var(--color-aqua);
  }

  label {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 20px 25px;
  }

  p {
    font-size: 17px;
  }

  .Description {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
