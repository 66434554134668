@font-face {
  font-family: 'ABCDiatype';
  src: url('../fonts/ABCDiatype-Light.otf') format('opentype');
}


.CheckoutForm {
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 1302px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255);
  z-index: 3;
  overflow: auto;

  @media screen and (min-width: 1302px) {
    border-left: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
  }

  label {
    right: 0;
    height: 43px;
    width: 43px;
    background-color: var(--color-white);
    top: 50%;
  }

  input {
    min-height: 65px;

    box-sizing: border-box;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    margin-top: 0;
  }

  & .CloseButton {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  & .WrapperTitle {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    margin-bottom: 70px;

    & h3 {
      margin: 0;
      font-size: 30px;
      font-weight: 200;
    }

    & .ExitCross {
      width: 20px;
    }
  }

  & .Description,
  .Terms {
    padding: 20px 25px;

    border-bottom: 1px solid var(--color-black);

    & .Title {
      font-size: 20px;
      margin: 0;
    }

    & .Text {
      margin: 0;
      margin-top: 1vw;
      color: var(--color-black-opacity);

      a {
        color: var(--color-black-opacity);
        font-size: 17px;
      }
    }
  }

  .Terms {
    padding-top: 70px;
  }

  & .Express {
    border-bottom: 1px solid var(--color-black);
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    margin-bottom: 70px;

    input[type="checkbox"] {
      border: none;
      position: absolute;
      height: 65px;
      width: 100%;
      opacity: 0;
    }
    input[type="checkbox"] + label {
      content: none;
    }

    input[type="checkbox"]:disabled + label {
      opacity: 0.2;
    }

    input[type="checkbox"]:checked + label {
      content: "";
      background-color: var(--color-aqua);
    }

    label {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      padding: 0 25px;
      justify-content: space-between;
    }

    & p {
      margin: 0;
    }
  }

  & .Delivery {
    border-bottom: 1px solid var(--color-black);
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;

    input[type="radio"] {
      border: none;
      position: absolute;
      height: 65px;
      opacity: 0;
    }

    input[type="radio"] + label {
      content: none;
      height: 65px;
    }

    input[type="radio"]:disabled + label {
      opacity: 0.2;
    }

    input[type="radio"]:checked + label {
      content: "";
      background-color: var(--color-aqua);
    }

    label {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 0 25px;
      justify-content: space-between;
      width: auto;
    }
  }

  & .DeliveryItem {
    width: 100%;
    white-space: normal;

    &:first-child {
      border-bottom: 1px solid var(--color-black);
    }

  }

  & input[type="text"],
  & input[type="email"],
  & input[type="tel"] {
    width: 100%;
  }

  & input {
    padding: 0 25px;
    border-bottom: 1px solid var(--color-black);
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: var(--color-brown);
    box-sizing: border-box;
  }

  .DateInput {
    width: 35%;

    border: none;
    padding: 0;
    background-color: var(--color-white);
  }

  & .CheckBoxContainer,
  .News {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-black);
    padding: 20px 25px;
    background-color: rgb(255, 255, 255);

    position: relative;

    & p {
      margin: 0;
      width: 70%;
    }

    label {
      position: relative;
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      padding: 0;
      margin: 0;
      right: 0;
      height: 43px;
      width: 43px;
      top: 50%;
      transform: translateY(-50%);
    }

    /* Create a custom checkbox */
    .Checkmark {
      border-radius: 50%;
      border: 1px solid var(--color-black);
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 43px;
      width: 43px;
      background-color: var(--color-white);
      top: 50%;
      transform: translateY(-50%);
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .Checkmark {
      content: "";
      background-image: url(../../public/images/checkmark.svg);
      background-repeat: no-repeat;
      background-color: var(--color-aqua);
      background-position: center;
    }

    .Checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .input:checked ~ .Checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .Checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    a {
      color: var(--color-black);
    }
  }

  & .OrderList {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  & .Order {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 25px;
    background-color: rgb(255, 255, 255);

    min-height: 65px;

    &:last-child {
      border-bottom: 0;
    }
  }

  & .OrderCategory {
    display: block;
  }

  & p {
    margin: 0;
  }

  & .DiscountCode {
    margin-bottom: 70px;
  }

  & .News {
    margin-bottom: 70px;
  }

  & .TotalCost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-black);
    border-top: 1px solid var(--color-black);
    padding: 0 25px;
    background-color: rgb(255, 255, 255);
    min-height: 65px;

    & p {
      margin: 0;
    }
  }

  & .DateTimeContainer {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4vw 5vw;
    background-color: rgb(255, 255, 255);

    span {
      display: block;
    }

    & p {
      margin: 0;
      text-align: center;
    }
  }

  & .SendOrder {
    color: var(--color-black);
    display: flex;
    cursor: pointer;

    width: 100%;
    justify-content: space-between;
    border-top: 1px solid var(--color-black);
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--color-black);
    padding: 0 25px;
    height: 65px;

    background-color: var(--color-yellow);
    font-weight: normal;
    -webkit-border-radius: 0;
    -webkit-appearance: none;

    & .Icon {
      width: 2vw;
    }
  }

  & .ImageContainer {
    & img {
      width: 250px;
      margin: auto;
      display: block;
      padding: 30px 0;
    }
  }

  & .Disable {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    padding: 0 25px;
    background-color: var(--color-yellow);

    & p {
      color: var(--color-black-opacity);
    }

    & .Icon {
      width: 2vw;
      opacity: 0.6;
    }
  }
}



.DropDownContainer {
  margin: 15px;
  display: flex;
  justify-content: flex-end;
}

.DropDownLabel {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.DropDownSelect {
  padding: 15px;
  font-size: 18px;
  font-family: 'ABCDiatype', Arial, Helvetica, sans-serif;
  color: black;
  border: 0px solid #8CD7FF;
  background-color: #8CD7FF;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover,
  &:focus {
    border-color: beige;
  }

  /* Media query for skjermer mindre enn 600px */
  @media (max-width: 600px) {
    width: 100%; /* eller en annen bredde, avhengig av designbehov */
    font-size: 16px; /* juster om nødvendig for mindre skjermer */
    padding: 10px; /* juster om nødvendig for mindre skjermer */
  }
}

.addButton {
  background-color: #66B584;
  border: none;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  text-align: left;
}

.removeButton {
  margin-top: 0px;
  margin: 10px;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #FF8300;
}

.productContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Legger til mellomrom mellom boksene */
}

.productBox {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  min-width: 100px; /* Minimumsbredde for hver boks */
  text-align: center;
}

.productBox.selected {
  background-color: #66B584; /* Endre denne fargen etter ditt design */
  color: white;
}