.NavLink {
  border-bottom: 1px solid var(--color-black);
  background-color: rgb(255, 255, 255);
  height: 65px;

  a {
    align-items: center;
    cursor: pointer;
    color: var(--color-black);
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    text-decoration: none;
  }

  & .Icon {
    width: 9px;
    height: 17px;
  }
}
