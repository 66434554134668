.OrderError {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & p {
    width: 60%;
    margin: 60vw auto;
    display: block;
    text-align: center;
  }
}
