.popup {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: auto;
  max-width: 250px;
  box-sizing: border-box;
  text-align: left; /* Allign all content to the left */

  h2 {
    padding: 1rem 1rem 1.5rem 1.5rem; /* Adjusted padding: more padding to the left, removed bottom padding */
    border-bottom: 1px solid black;
    margin-bottom: 0; /* Removed margin to eliminate space between h2 and the button */
  }

  button {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem; /* More padding on the left */
    width: 100%;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left; /* Align button text to the left */

    &:hover {
      background-color: #8CD7FF;
    }
  }

  .confirm-button {
    background-color: #D3FF8C;
    border-top: 0px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    text-align: left;
    padding-left: 1.5rem; /* More padding on the left */
  }

  .close-button {
    background-color: white;
    color: black;
    border-top: 0px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    text-align: left;
    padding-left: 1.5rem; /* More padding on the left */
  }

  input {
    background-color: #F7F6EB;
    margin: 0; /* Removed margin */
    padding: 0.6rem 0.6rem 0.6rem 1.5rem; /* More padding on the left */
    font-size: 1rem;
    width: calc(100%);
    border: 1px solid black;
    box-sizing: border-box;
    text-align: left; /* Align input text to the left */
  }

  &.valid-code {
    background-color: white;
    color: black;
    width: 90px;
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    margin-bottom: 40px;
    border: 1px solid black;
    opacity: 1;

    &:hover {
      transition: opacity 300ms;
      opacity: 0;
    }
  }
}

/* Responsivitet for forskjellige skjermstørrelser */
@media (min-width: 100px) {
  .popup {
    width: 60%;

    &.valid-code {
      left: 80%;
    }
  }

  .iconButton {
    left: 80%;
  }
}

@media (min-width: 900px) {
  .popup {
    width: 40%;
    max-width: 300px;

    &.valid-code {
      left: 90%;
    }
  }

  .iconButton {
    left: 90%;
  }
}

/* Animer inn og ut med fade */
.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.popup-exit {
  opacity: 1;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.iconButton {
  width: 120px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  border: 1px solid black;
  opacity: 0.9;

  &:hover {
    transition: opacity 300ms;
    opacity: 1;
    cursor: pointer;
  }
}