.InfoBox {
  border-bottom: 1px solid var(--color-black);
  padding: 25px;

  & h2 {
    font-weight: lighter;
    margin-top: 0;
  }

  & p {
    line-height: 1.25;
    max-width: 800px;
  }
}
