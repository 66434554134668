.Confirmation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & .ImageContainer {
    & img {
      width: 250px;
      margin: 20vw auto;
      margin-bottom: 10vw;
      display: block;
    }
  }

  & p {
    width: 60%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  & .NumberedList {
    counter-reset: item;
    list-style-type: none;
    padding: 0 0 0 25px;

    margin: 25px 0;

    & .NumberedListElement {
      display: block;
      padding: 0 25px;
      margin-bottom: 25px;
      font-size: 20px;
    }

    & .NumberedListElement:before {
      content: counter(item) "  ";
      counter-increment: item;
      margin-left: -30px;
      padding-right: 13px;
    }
  }

  & .ListLink {
    counter-reset: item;
    list-style-type: none;
    padding: 25px 0 0 0;

    margin-left: -25px;

    & .ListLinkItem {
      border-top: 1px solid var(--color-black);
      height: 65px;
      margin: 0 -25px;

      a {
        align-items: center;
        text-decoration: none;
        color: var(--color-black);
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 25px;

        p {
          text-align: left;
          margin: 0;
        }
      }

      @media screen and (min-width: 1302px) {
        a:hover {
          background-color: var(--color-brown);
        }
      }
    }

    & .ListLinkItem:last-child {
      border-bottom: 1px solid var(--color-black);
    }
  }

  & .IntroText {
    padding-bottom: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
    }
  }

  & .Text {
    padding-bottom: 60px;
  }

  & .Price {
    font-size: 30px;
  }

  & .ListText {
    margin: 0;
    width: auto;
    text-align: left;
    margin-top: -26px;
  }

  @media screen and (min-width: 1302px) {
    & .ListText {
      max-width: 60%;
    }
  }

  .ConfirmationSection {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
