.CategoryBox {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-bottom: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  padding: 20px 25px;

  & p {
    margin: 0;
    padding-top: 1em;
  }

  &:last-child {
    border-right: none;
  }

  .CategoryImg {
    display: block;
    margin: 0 auto;
  }

  & .CategoryImage {
    padding: 20px 20px 0 20px;
    margin: auto;
    margin-bottom: 20px;
  }

  &.CategoryActive {
    background-color: var(--color-white) !important;
  }
}
