.RepairForm {
  & .Container {
    border-top: 1px solid var(--color-black);
    background-color: var(--color-yellow);
    max-width: 1302px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 3;

    @media screen and (min-width: 1302px) {
      width: auto;
      min-width: 610px;
      top: 50%;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-left: 1px solid var(--color-black);
      border-right: 1px solid var(--color-black);
    }

    & .Option {
      padding: 20px 25px;
      border-bottom: 1px solid var(--color-black);
      line-height: 1.5;

      & .Wrapper {
        display: flex;
        justify-content: space-between;

        h2 {
          margin: 0;
          font-weight: 200;
        }
      }

      p {
        margin-top: 0.5em;
        margin-bottom: 0;
        color: var(--color-black-opacity);
      }
    }

    & .input {
      width: 100%;

      border-bottom: 1px solid var(--color-black);
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: var(--color-brown);
      min-height: 95px;
      padding: 25px;
      box-sizing: border-box;
      position: relative;
      margin: 0;
      padding: 20px;
      &::placeholder {
        position: absolute;
      }

      &::-webkit-input-placeholder {
        padding-right: 10px;
      }

      @media screen and (min-width: 1302px) {
        padding: 20px;
      }
    }

    & .input:focus {
      width: 100%;
      min-height: 115px;
      border-bottom: 1px solid var(--color-black);
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: var(--color-brown);
      padding: 25px;

      &::placeholder {
        top: 20px;
        transform: translateY(0);
      }
    }

    & .button {
      padding: 20px 25px;
      border-bottom: 1px solid var(--color-black);
      border-top: none;
      border-left: none;
      border-right: none;
      display: flex;
      justify-content: space-between;
      background-color: var(--color-yellow);
      cursor: pointer;

      & p {
        margin: 0;
        color: var(--color-black);
      }

      & img {
        width: 20px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  & .Overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  & .Warning {
    background-color: var(--color-warning);

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    a {
      display: block;
      padding: 25px 20px;
      text-decoration: none;
      cursor: pointer;
    }

    p {
      color: var(--color-black-opacity);
      margin: 0;
      line-height: 30px;
    }
  }

  & .WarningTitle {
    display: flex;
    justify-content: space-between;
  }
}
